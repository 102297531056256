<script setup lang="ts">
import { NuxtLink, NuxtLinkLocale } from '#components'

const props = defineProps<{
  to?: string
  href?: string
  target?: string
}>()

const isRelative = computed(() => (props.to ?? props.href ?? props.target ?? '').startsWith('/'))
</script>

<template>
  <Component
    :is="(href || target) ? 'a' : isRelative ? NuxtLinkLocale : NuxtLink"
    v-bind="$props"
    class="n-link hover:n-link-hover n-transition n-link-base"
  >
    <slot />
  </Component>
</template>
